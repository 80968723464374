import { type TeamsPermissionApi } from '@atlassian/teams-client/types';

import { type TeamsPermissions } from './types';

export const transformPermissions = (permissions: TeamsPermissionApi[]): TeamsPermissions => {
	return permissions.reduce<TeamsPermissions>(
		(acc, permission) => {
			switch (permission) {
				case 'CAN_CREATE_TEAMS':
					return { ...acc, canCreateTeams: true };
				case 'CAN_VIEW_TEAMS':
					return { ...acc, canViewTeams: true };
				case 'CAN_ADMIN_TEAMS':
					return { ...acc, canAdminTeams: true };
				default:
					return acc;
			}
		},
		{
			canCreateTeams: false,
			canViewTeams: false,
			canAdminTeams: false,
		},
	);
};
