import { createHook, createStore } from 'react-sweet-state';

import { HttpError, teamsClient } from '@atlassian/teams-client';

import { type TeamsPermissionsActions, type TeamsPermissionsStore } from './types';
import { transformPermissions } from './utils';

const actions: TeamsPermissionsActions = {
	getPermissions:
		({ orgId, cloudId, enabled }, { onFetch }) =>
		async ({ setState, getState, dispatch }) => {
			const { hasLoaded, isLoading } = getState();
			if (hasLoaded || isLoading || !enabled || !orgId || !cloudId) {
				return;
			}
			try {
				setState({ isLoading: true });
				teamsClient.setContext({ orgId, cloudId });
				const response = await teamsClient.getPermissions();
				dispatch(actions.setPermissions(response.permissions));
				onFetch?.();
			} catch (error: unknown) {
				dispatch(
					actions.setError(
						error instanceof HttpError ? error : new Error('Failed to fetch permissions'),
					),
				);
			}
		},
	setError:
		(error) =>
		({ setState }) => {
			setState({ hasLoaded: true, isLoading: false, error });
		},
	setLoading:
		(isLoading) =>
		({ setState }) => {
			setState({ isLoading });
		},
	setPermissions:
		(permissions) =>
		({ setState }) => {
			setState({
				hasLoaded: true,
				isLoading: false,
				permissions: transformPermissions(permissions),
				permissionsResponse: { permissions },
			});
		},
};

const Store = createStore<TeamsPermissionsStore, TeamsPermissionsActions>({
	initialState: {
		error: undefined,
		hasLoaded: false,
		isLoading: false,
		permissions: {
			canCreateTeams: true,
			canViewTeams: true,
			canAdminTeams: false,
		},
	},
	actions,
	name: 'teams-permissions',
});

export const useTeamsPermissionsStore = createHook(Store);
