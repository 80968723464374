import { DEFAULT_CONFIG } from '../constants';
import { RestClient } from '../rest-client';

import { type MutabilityContraints, transformUserManageConfig } from './utils';

const defaultConfig = {
	serviceUrl: `${DEFAULT_CONFIG.stargateRoot}/users`,
};

export class IdentityClient extends RestClient {
	constructor(config = {}) {
		super({ ...defaultConfig, ...config });
	}

	async getUserManageConfig(userId: string) {
		return this.getResourceCached<MutabilityContraints>(`/${userId}/manage`).then((resp) => {
			return transformUserManageConfig(resp);
		});
	}

	async getMyAvatarUploadedStatus(userId: string) {
		return this.getResourceCached<{ uploaded: boolean }>(`/${userId}/manage/avatar`);
	}
}

export default new IdentityClient();
