import { useCallback, useEffect } from 'react';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { fireOperationalEvent } from '../analytics';

import { useTeamsPermissionsStore } from './teams-permission-store';
import { type TeamsPermissionsService } from './teams-permission-store/types';

export const useTeamsPermissionsService: TeamsPermissionsService = (
	scope,
	{ enabled, attributes } = { enabled: false, attributes: {} },
) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	// backwards compatibility allow orgId to be passed as a string
	const { orgId, cloudId } =
		typeof scope === 'string' || !scope ? { orgId: scope, cloudId: undefined } : scope;

	const [state, { getPermissions }] = useTeamsPermissionsStore();

	const fetchData = useCallback(async () => {
		getPermissions(
			{ orgId, cloudId, enabled },
			{
				onFetch: () =>
					fireOperationalEvent(createAnalyticsEvent, {
						action: 'fetched',
						actionSubject: 'use-team-permissions',
						attributes: {
							userId: attributes?.userId,
							orgId,
							cloudId,
						},
					}),
			},
		);
	}, [orgId, cloudId, enabled, getPermissions, createAnalyticsEvent, attributes?.userId]);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	return {
		loading: state.isLoading,
		data: state.permissionsResponse,
		error: state.error,
		...state,
	};
};
