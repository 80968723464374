import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next/types';

const ANALYTICS_CHANNEL = 'peopleTeams';

const runItLater = (cb: (arg: any) => void) => {
	if ((window as any).requestIdleCallback === 'function') {
		return (window as any).requestIdleCallback(cb);
	}

	if (typeof window.requestAnimationFrame === 'function') {
		return window.requestAnimationFrame(cb);
	}

	return () => setTimeout(cb);
};

type AnalyticsAttribute = Record<string, string | number | boolean | undefined>;

interface AnalyticsEvent {
	action?: string;
	actionSubject?: string;
	actionSubjectId?: string;
	attributes?: Record<string, string | number | boolean | undefined | AnalyticsAttribute>;
	name?: string;
	source?: string;
}

const fireEvent =
	(kind: 'operational' | 'screen' | 'track' | 'ui') =>
	(createAnalyticsEvent: CreateUIAnalyticsEvent | undefined, body: AnalyticsEvent) => {
		if (!createAnalyticsEvent) {
			return;
		}

		runItLater(() => {
			createAnalyticsEvent({
				eventType: kind,
				...body,
			}).fire(ANALYTICS_CHANNEL);
		});
	};

export const fireOperationalEvent = fireEvent('operational');
export const fireScreenEvent = fireEvent('screen');
export const fireTrackEvent = fireEvent('track');
export const fireUIEvent = fireEvent('ui');

export type ErrorWithStatus = Error & {
	status: number;
};

export const isSeriousError = (error?: ErrorWithStatus | Error) => {
	if (!error) {
		return false;
	}

	// Check for ' 403' with space beforehand to not count urls containing 403 in cloud or team id
	if (('status' in error && error['status'] === 403) || error.message.includes(' 403')) {
		return false;
	}

	if (('status' in error && error['status'] === 401) || error.message.includes(' 401')) {
		return false;
	}

	if (('status' in error && error['status'] === 429) || error.message.includes(' 429')) {
		return false;
	}

	if (error.message === 'Failed to fetch') {
		return false;
	}

	return true;
};
