// Copied from ptc-common/user type to not messing up dependency
export enum FieldType {
	FullName = 'fullName',
	Nickname = 'nickname', // aka `Public name`
	Title = 'title',
	Department = 'department',
	CompanyName = 'companyName',
	Location = 'location',
	Timezone = 'timezone',
	Email = 'email',
	PhoneNumber = 'phoneNumber',
	Avatar = 'picture',
	Language = 'language',
	LocalTime = 'localTime',
	RemoteWeekdayIndex = 'remoteWeekdayIndex',
	RemoteWeekdayString = 'remoteWeekdayString',
	RemoteTimeString = 'remoteTimeString',
}

type FieldKey =
	| 'fullName'
	| 'nickname'
	| 'timezone'
	| 'locale'
	| 'title'
	| 'phoneNumber'
	| 'companyName'
	| 'department'
	| 'location'
	| 'email';

export interface SimpleConstraint {
	allowed: boolean;
	reason?: { key: string };
}

export type MutabilityContraints = {
	[key: string]: SimpleConstraint | MutabilityContraints;
};

export const MUTABILITY_DETAILS_MAP: Record<FieldKey, string> = {
	fullName: 'name',
	nickname: 'nickname',
	timezone: 'zoneinfo',
	locale: 'locale',
	email: 'email',
	// extended profile
	title: 'extended_profile.job_title',
	phoneNumber: 'extended_profile.phone_number',
	companyName: 'extended_profile.organization',
	department: 'extended_profile.department',
	location: 'extended_profile.location',
};

export const CUSTOM_FIELD_PLACE = {
	email: 'email.initiateChange',
	picture: 'avatar',
} as const;

export type CustomFieldKey = keyof typeof CUSTOM_FIELD_PLACE;
export type AllFieldKeys = FieldKey | CustomFieldKey;

const getFieldConfig = (
	data: MutabilityContraints,
	field: AllFieldKeys,
): SimpleConstraint | undefined => {
	if ((field === 'email' || field === 'picture') && CUSTOM_FIELD_PLACE[field]) {
		return data[CUSTOM_FIELD_PLACE[field]] as SimpleConstraint | undefined;
	}
	const mappedFieldKey =
		field in MUTABILITY_DETAILS_MAP ? MUTABILITY_DETAILS_MAP[field as FieldKey] : undefined;

	if (!mappedFieldKey) {
		return undefined;
	}

	const profileWrite = data['profile.write'] as MutabilityContraints;
	return profileWrite[mappedFieldKey] as SimpleConstraint | undefined;
};

const convertFieldKeyToFieldType = (key: AllFieldKeys): FieldType | undefined => {
	// Check if the key can be mapped to a FieldType value
	if (Object.values<string>(FieldType).includes(key)) {
		return key as FieldType;
	}
	return undefined;
};

const getMutabilityConstraintFor = (
	data: MutabilityContraints,
	field: AllFieldKeys,
): { field: FieldType; reason: string | null } | undefined => {
	const fieldConfig = getFieldConfig(data, field);
	if (!fieldConfig || fieldConfig.allowed) {
		return undefined;
	}

	const convertedField = convertFieldKeyToFieldType(field);
	if (!convertedField) {
		return undefined;
	}

	const reason = fieldConfig.reason;
	return {
		field: convertedField,
		reason: (reason && reason.key) || null,
	};
};

export const transformUserManageConfig = (data: MutabilityContraints) => ({
	mutabilityConstraints: Object.keys(MUTABILITY_DETAILS_MAP)
		.map((field) => getMutabilityConstraintFor(data, field as FieldKey))
		.filter(
			(constraint): constraint is { field: FieldType; reason: string | null } =>
				constraint !== undefined,
		),
});
